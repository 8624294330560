import * as React from 'react';
import Layout from './components/Layout';
import './custom.css';

import UserProvider from './context/UserProvider';
import DefaultValueProvider from './context/DefaultValueProvider';
import AdminProvider from './context/adminProvider';

export default () => (
    <UserProvider>
        <DefaultValueProvider>
            <AdminProvider>
                <Layout />
            </AdminProvider>
        </DefaultValueProvider>
    </UserProvider>
);
