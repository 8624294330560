import * as QueryString from "query-string";
import * as React from "react";
import { Redirect } from "react-router";
import FileComponent from "../../components/dms/FilesComponent";
import { parseJwt } from "../../modules/common";

class Ingress extends React.Component<any, { isSuccess: boolean }> {
    constructor(props: any) {
        super(props);

        this.state = {
            isSuccess: false
        }
    }

    componentDidMount() {
        window.history.pushState({}, "ingress", "/");
        console.log("/Ingress")
        const { token } = QueryString.parse(this.props.location.search);

        console.log(token)

        if (token) {
            var jwt = parseJwt(String(token))
            var extra = JSON.parse(jwt.extra)
            var data = {
                access_token: token
            }

            localStorage.removeItem('DMS_APPLICATION');
            localStorage.setItem('DMS_APPLICATION', JSON.stringify(data));

            this.setState({
                isSuccess: true
            });
        }
    }

    componentDidUpdate() {
    }

    render() {
        let { isSuccess } = this.state

        console.log(isSuccess)

        if (!isSuccess) {
            return <Redirect to='/' />
        }

        return (
            <div>
                <FileComponent />
            </div>
        );
    }
}

export default Ingress
